<template>
  <div
    v-if="
      currentSiteSettings.facebook && currentSiteSettings.facebook.integrate
    "
    :class="[
      'z-[1000] fixed right-6 bottom-4', // Adjust as needed
      currentSiteSettings.intercom.integrate && currentSiteSettings.whatsapp
        ? 'double-has-bottom'
        : currentSiteSettings.intercom.integrate || currentSiteSettings.whatsapp
        ? 'has-messenger-bottom'
        : 'has-bottom',
    ]"
  >
    <a
      :href="`http://m.me/${currentSiteSettings.facebook.page_id}`"
      target="_blank"
    >
      <NuxtImg
        src="/facebook-messenger.png"
        :sizes="{ default: '40px', sm: '40px' }"
        loading="lazy"
        format="webp,avif"
        alt="Messenger Meme"
        width="40"
        height="40"
        class="w-[40px] h-auto rounded-[8px]"
      />
    </a>
  </div>
</template>

<script setup>
import { useMainStore } from "@/store/index";
const store = useMainStore();
const currentSiteSettings = store.currentSiteSettings;
</script>
<style>
.has-messenger-bottom {
  @apply bottom-20;
}
.double-has-bottom {
  @apply bottom-[170px];
}
</style>
